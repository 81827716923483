import { useState } from "react"
import Modal from "./components/Modal"
import WButton from './components/WButton.js'

const Page = () => {
    const [modalActive, setModalActive] = useState(false)
    return (
        <div>
            <Modal active={modalActive} setActive={setModalActive}>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean neque ante, vestibulum sit amet gravida sit amet, lobortis vel eros. Nullam auctor tortor non odio scelerisque, eget semper diam interdum. Ut consectetur nisi non diam consequat tincidunt. Aenean blandit dui id nulla egestas, non suscipit nisi ornare. Integer quis fermentum metus. Pellentesque porttitor neque sit amet turpis placerat semper. Fusce ultricies sem id metus cursus imperdiet. Mauris sit amet magna accumsan, volutpat orci quis, luctus ipsum. Nulla aliquam consectetur nisi, ac dictum ante fermentum id. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent pellentesque.</p>
            </Modal>
            <WButton  toDo={() => setModalActive(true)} style={{
                backgroundColor: "#a2e0bc",
                color: "#636363",
                fontSize: 32,
            }}>More</WButton>
            <h1 style={{
                fontSize: 42,
                color: '#a2e0bc'
            }}>Create account, login, download our games, etc.</h1>
        </div>
    )
}

export default Page