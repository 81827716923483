import './WButton.css'

const WButton = ({toDo, children}) => {
    return (
        <button onClick={toDo} className="button">
            <span className="text">{children}</span>
        </button>
    )
}

export default WButton