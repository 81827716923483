import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Col } from 'reactstrap';

const styles = [
  {
    background: '#c2b0ff'
  },
  {
    fontSize: 42,
    color: '#a2e0bc'
  },
  {
    fontSize: 20,
    color: '#636363'
  },
  {
    fontSize: 42,
    color: '#a2e0bc'
  },
  {
    fontSize: 32,
    color: '#a2e0bc'
  },
  {
    fontSize: 28,
    color: '#636363'
  },
  {
    fontSize: 28,
    color: '#636363'
  }
]

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p style={styles[2]}>{children}</p>,
    [BLOCKS.HEADING_1]: (node, children) => <h1 style={styles[3]}>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2 style={styles[4]}>{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3 style={styles[5]}>{children}</h3>
  }
}

const Article = function ({ title, addition, content }) {
  //const content = documentToReactComponents(document)


  return (
      <Col>
        <div className='card' style={styles[0]}>
          <img src={addition} className='card-img-top'/>
          <div className='card-body'>
            <h2 className='card-title' style={styles[1]}>{title}</h2>
            <div className='card-text'>
              {documentToReactComponents(content, options)}
            </div>
          </div>
        </div>
      </Col>
  )
}

export default Article
