const title = {  
  fontSize: 32,
  color: '#a2e0bc'
}

const text = {
  fontSize: 20,
  color: '#636363'
}

const Content = () => {
  return (
    <>
      <div className="lead">
        <h1 style={title}>ITFichi - interesting blog</h1>
        <p style={text}>Here you can find a lot of interesting and useful things about games, programming, etc.</p>
      </div>
    </>
  )
}

export default Content
