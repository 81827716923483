import useSWR from 'swr'
import { Spinner } from '@contentful/f36-spinner'
import { createClient } from 'contentful'
import Article from './components/Article'
import Content from './Content'
import { Container, Row } from 'reactstrap'
import Page from "./Page"

const client = createClient({
  space: process.env.CONTENTFUL_SPACE_ID,
  accessToken: process.env.CONTENTFUL_DELIVERY_TOKEN,
})

const fetcher = async () => {
  const entryItems = await client.getEntries({ content_type: 'article' })

  const entries = entryItems.items.map((entry) => {
    const { fields } = entry
    return {
      title: fields.title,
      addition: fields.addition.fields.file.url,
      content: fields.desc,
    }
  })

  console.log(entries)

  return { entries }
}

function App() {
  const { data, error } = useSWR('contentful', fetcher)

  if (error) {
    console.log(error)
    return <div style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      fontSize: 32,
      color: "#636363"
    }}>Failed to load <br/> <a href='#' onClick={() => location.reload()}>Reload</a></div>
  }
  if (!data) return <Spinner size="large" style={{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }}/>

  const { entries } = data

  const articles = entries
    .map(({ title, addition, content}, i) => {
      return (
        <Article
          key={i}
          title={title}
          addition={addition}
          content={content}
        ></Article>
      )
    })

  return (
    <main className='cover-container d-flex w-100 h-100 p-3 mx-auto flex-column'>
      <Content />
      <hr />
      <Container className='px-3'>
        <Row>
          {articles}
        </Row>
      </Container>
      <hr/>
      <Page/>
      <hr/>
      <footer className='mt-auto text-white-50'>
        <p style={{color: '#636363', fontSize: 28}}>ITFichi Inc &copy;</p>
      </footer>
    </main>
  )
}

export default App
